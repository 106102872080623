.react-autowhatever-1 {
    list-style: none;
}

.checkboxList {
    display: flex;
    flex-direction: column;
    font-size: large;
    margin: 5px;
    color: black;
  }