.titlecontainer {
    display: flex;
    justify-content: center; 
    margin: 10px;
}

.title {
    font-size: x-large;
    text-align: center;
}

.linkcontainer {
    border: 1px solid black;
    margin: 10px;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Create a grid with auto-filling columns */
    grid-gap: 10px; /* Add space between the buttons */
}

.homebutton {
    margin: 10px;
}

.homebutton:hover {
    cursor: pointer;
}

.downloadbutton {
    margin: 10px;
}

@media (max-width: 600px) {
    .linkcontainer {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust grid columns for smaller screens */
    }
}
