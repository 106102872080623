.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #9DEDED;
  }
  
  .column {
    font-size: larger;
  }
  
  .header {
    margin: 10px 0px 0px 10px;
    text-align: left;
    font-weight: bold;
  }
  
  .subheader {
    margin: 0px 0px 0px 10px;
    text-align: left;
  }
  
  .logoColumn {
    text-align: right;
  }
  
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    height: 100; 
  }
  
  .databrowsing {
    font-weight: bold;
    font-size: x-large;
    margin-left: 10px;
  }
  
  .dataType {
    margin: 10px 10px 0px 10px;
    font-size: larger;
  }
  
  .select {
    margin: 0px 10px 0px 10px;
  }
  
  .checkboxcontainer {
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    margin: 10px;
    font-size: large;
    justify-content: space-between;
  }
  
  .preptypecontainer {
    margin: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .preptype {
    display: inline-block;
    margin-left: 10px;
    font-size: larger;
  }
  
  .sexcontainer {
    margin: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .sex {
    display: inline-block;
    margin-left: 10px;
    font-size: larger;
  }
  
  .diseasegroupcontainer {
    margin: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .diseasegroup {
    display: inline-block;
    margin-left: 10px;
    font-size: larger;
  }
  
  .celltypecontainer {
    display: flex;
    flex-direction: row;
  }
  
  .celltype {
    display: inline-block;
    margin-left: 10px;
    font-size: larger;
  }
  
  .diseasegroupcontainer {
    margin: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .diseasegroup {
    display: inline-block;
    margin-left: 10px;
    font-size: larger;
  }
  
  .celltypecontainer2 {
    margin: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .celltypeinvisible {
    display: inline-block;
    margin-left: 10px;
    font-size: larger;
    color: transparent;
  }
  
  .checkboxList {
    display: flex;
    flex-direction: column;
    font-size: large;
    margin: 5px;
    color: black;
  }
  
  .inciteseqcontainer {
    font-size: large;
    color: black;
    width: 10rem;
  }
  
  .inciteseq {
    font-size: large;
  }
  
  .column1 {
    display: flex;
    flex-direction: column;
    margin: 10px;
    font-size: larger;
  }
  
  .graphtitle {
    text-align: center;
    font-size: larger;
    margin: 10px;
  }
  
  .graph {
    display: flex;
    justify-content: flex-start;
  }
  
  .graph img {
    width: auto;
    height: 100%;
  }
  
  .loading {
    margin-top: 10px;
    font-size: larger;
    animation: spin 1s linear infinite;
  }
  
  .submit {
    margin: 0px 10px 10px 10px;
  }
  
  .error {
    margin-top: 10px;
    font-size: larger;
  }


  .loader {
    border: 7px solid transparent; /* Set border to transparent */
    border-top-color: #085d96; /* Set top border color to blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: auto; 
    margin-top: 10px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
/* --------------------------------------------------------------- */



@media only screen and (max-width: 768px) {
  
  .checkboxcontainer {
    display: flex;
    flex-wrap: wrap; 
  }

  .graph img {
    width: 100%; 
    height: auto; 
  }

}